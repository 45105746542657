import { Auth, API } from "aws-amplify"

export default {
  findByOrderNo: async (order_no, cancelable = true) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const result = await API.get("smariDeliveryboxAPI", `/gmo-payment/${order_no}/search?cancelable=${cancelable}`, init)

    return result
  },
  cancel: async (order_no) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const result = await API.post("smariDeliveryboxAPI", `/gmo-payment/${order_no}/cancel`, init)

    return result
  },
  execute: async (order_no) => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken()
    const init = {
      headers: {
        Authorization: token,
      },
    }
    const result = await API.post("smariDeliveryboxAPI", `/gmo-payment/${order_no}/execute`, init)

    return result
  }
}
