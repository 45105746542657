<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content" v-if="is_allow_action">
      <div class="reception-info">
        <p>以下の受付をキャンセルしますか？</p>
        <p>
          <b>受付日時&nbsp;&nbsp;{{ deliverybox_reception.created_at }}</b><br />
          <b>事業者名&nbsp;&nbsp;{{ deliverybox_reception.service_name }}</b>
        </p>
      </div>
      <div class="btn-wrapper" v-if="!is_done">
        <button class="button is-rounded btn-cancel" @click="toReceptionHistory">
          いいえ
        </button>
        <button class="button is-rounded btn-submit" @click="cancelReception">
          はい
        </button>
      </div>
      <article class="message is-success is-small" v-if="success_message">
        <div class="message-body">
          {{ success_message }}
        </div>
      </article>
      <p>
        ※発払のお客様には、発送料金を払い戻しさせていただきます。お客様のお手続きは必要ございません。
      </p>
    </div>
  </div>
</template>
<script>
import GMOPaymentService from "../services/GMOPaymentService"
import DateService from "../services/DateService"
import Loading from "vue-loading-overlay"

export default {
  name: "ReceptionCancel",
  data() {
    return {
      gmo_payment: {
        order_no: "",
      },
      deliverybox_reception: {
        service_name: "",
        created_at: "",
      },
      is_loading: false,
      is_allow_action: false,
      is_done: false,
      error_message: "",
      success_message: "",
    }
  },
  components: {
    Loading,
  },
  computed: {},
  async beforeMount() {
    this.is_loading = true
    try {
      if (!this.$route.params.order_no) {
        this.error_message = "必要な情報が存在しないため操作ができません。最初から操作を行ってください。"
        return
      }
      const gmo_payment = await GMOPaymentService.findByOrderNo(this.$route.params.order_no)

      this.gmo_payment.order_no = gmo_payment.order_no
      this.deliverybox_reception.created_at = DateService.formatDatetime(gmo_payment.deliverybox_reception.created_at)
      this.deliverybox_reception.service_name = gmo_payment.deliverybox_reception.service_name

      this.is_allow_action = true
    } catch (error) {
      this.error_message = "エラーが発生しました。しばらく時間をおいて、最初から操作を行ってください。"
    } finally {
      this.is_loading = false
    }
  },
  methods: {
    toReceptionHistory() {
      this.$router.push({
        path: "/reception/history",
      })
    },
    async cancelReception() {
      this.is_loading = true
      try {
        await GMOPaymentService.cancel(this.gmo_payment.order_no)
        this.is_done = true
        this.success_message = "受付をキャンセルいたしました。"
      } catch (error) {
        this.error_message = "エラーが発生しました。しばらく時間をおいて、最初から操作を行ってください。"
      } finally {
        this.is_loading = false
      }
    },
  },
}
</script>

<style scoped>
.reception-info {
  margin-bottom: 50px;
}
.btn-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 50px;
}
</style>
